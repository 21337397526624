import { useMemo, useCallback } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import cn from 'classnames';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { sharedToastProps, toast } from '@/services/toast';
import isEmpty from 'lodash/isEmpty';
import iconHatch from '@/assets/images/icon--hatch.png';
import type { NFTItem } from '@/types/NFTItem';
import Spinner from 'react-bootstrap/Spinner';
import { selectNFTDetailModal } from '@/modules/NFTDetail/selectors';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import messagesDetail from '@/components/NFTDetailViewModal/messages';
import HammerBurnInstructrionModal from './HammerBurnInstructrionModal';
import useCheckBalance from '@/hooks/useCheckBalance';
import { csprToMote } from '@/helpers/balance';

import useLedgerErrorsHandler from '@/hooks/useLedgerErrorsHandler';
import { handleDeployResult } from '@/modules/EggPurchase/actions';
import { lockAndReloadEggDetail } from '@/modules/NFTDetail/actions';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import NetworkFeePanel from '@/components/NetworkFeePanel';
import commonMessages from '@/constants/commonMessages';
import LoadingBox from '@/components/LoadingBox';
import { NFTEggStatus } from '@/types/NFTItem';
import {
  selectHammerBurnModule,
  selectHammerBurnStatusModal,
} from '@/modules/HammerBurn/selectors';
import { hammerBurnActions } from '@/modules/HammerBurn/store';
import useLoadHammers from '@/hooks/useLoadHammers';
import { selectNFTCollectionModal } from '@/modules/EggCollection/selectors';
import InfiniteLoader from '@/components/InfiniteLoader';
import { Box } from './Box';
import FireCircle from './FireCircle';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { burnHammerProcess } from '@/modules/HammerBurn/actions';
import useHammerBurn from '@/hooks/useHammerBurn';
import configs from '@/constants/settings';
import HammerBurnStatusModal from './HammerBurnStatusModal';
import Sample2 from '@/assets/images/hammer-sample.png';
import EmptyHammersToBurn from './EmptyHammersToBurn';

type PropType = {
  // slides: number[]
  children: React.ReactNode;
  options?: EmblaOptionsType;
  className?: string;
};

const CardCarousel: React.FC<PropType> = (props) => {
  const { children, className, options } = props;
  const [emblaRef] = useEmblaCarousel(options);

  return (
    <div className={cn('embla', className)}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container gap-x-2 justify-center mx-auto flex">
          {children}
        </div>
      </div>
    </div>
  );
};

const HammerBurnModal = () => {
  const HAMMER_BURN_PAYMENT_AMOUNT = configs.HAMMER_BURN_PAYMENT_AMOUNT;
  const { fetchMore, hasMore, isValidating, isLoading, viewData, refetch } =
    useLoadHammers();
  const nftCollectionModalState = useSelector(selectNFTCollectionModal);
  const { pagination } = nftCollectionModalState;
  const { burnHammer, isDeploying } = useHammerBurn();
  const { checkBalanceAgainstAmount } = useCheckBalance();
  const dispatch = useDispatch();
  const { open, selectedNFT } = useSelector(selectHammerBurnModule);

  const { loading: statusLoading } = useSelector(selectHammerBurnStatusModal);
  const { isLedgerConnected, toastError } = useLedgerErrorsHandler();
  const NFTDetailStore = useSelector(selectNFTDetailModal);
  const { data: dataNFTDetail } = NFTDetailStore;
  const {
    token_uri: image,
    tokenId,
    egg,
    isInstallment,
  } = useMemo(() => {
    try {
      if (!dataNFTDetail) {
        throw new Error('NFTData is missing');
      }
      return getNFTDetails(dataNFTDetail as NFTItem);
    } catch (error: any) {
      return {
        token_uri: null,
        tokenId: null,
        egg: null,
        isInstallment: null,
        classNFT: null,
      };
    }
  }, [dataNFTDetail]);

  const selectedHammer = useMemo(() => {
    if (!selectedNFT || !viewData?.length) {
      return undefined;
    }

    return viewData.find((item) => item.tokenId === selectedNFT);
  }, [selectedNFT, viewData]);
  const onCloseHandler = useCallback(() => {
    dispatch(hammerBurnActions.hideModal());
    dispatch(hammerBurnActions.selectNFT(null));
  }, [dispatch]);
  const shouldDisableHammerBurnButton = useMemo(() => {
    return Boolean(
      !selectedNFT ||
        isInstallment ||
        isDeploying ||
        statusLoading ||
        egg?.status === NFTEggStatus.incubating ||
        egg?.isProcessing,
    );
  }, [
    egg?.isProcessing,
    egg?.status,
    isDeploying,
    isInstallment,
    selectedNFT,
    statusLoading,
  ]);

  const onBurnHammer = useCallback(async () => {
    try {
      // Prevent making Dragon when not enough balance
      if (!checkBalanceAgainstAmount(csprToMote(HAMMER_BURN_PAYMENT_AMOUNT))) {
        toast.warn(
          messagesDetail.burnHammerNotEnoughCSPR(
            HAMMER_BURN_PAYMENT_AMOUNT.toString(),
          ).defaultMessage,
          {
            ...sharedToastProps,
            autoClose: true,
          },
        );
        return;
      }

      const result: any = await dispatch(
        burnHammerProcess({
          burnHammer,
          eggTokenId: tokenId?.toString(),
          hammerId: selectedNFT?.toString(),
          paymentAmount: HAMMER_BURN_PAYMENT_AMOUNT,
        }),
      ).unwrap();

      if (result) {
        setTimeout(async () => {
          dispatch(hammerBurnActions.setBurnStatusLoading(true));
          dispatch(hammerBurnActions.setBurnStatusResult(true));
          dispatch(hammerBurnActions.showBurnStatusModal());

          await dispatch(
            handleDeployResult({
              result: result?.deployResult,
              message: `Hammer burn process for token ${selectedNFT} is sent`,
              toastId: `hammer-burn-${selectedNFT}`,
              metadata: {
                from: 'nftDetail',
                id: tokenId,
                eggId: tokenId,
                action: 'hammer_burn',
                uri: image.value,
                seen: false,
              },
              configs: {
                skipStoringDeploy: isEmpty(result?.deployResult),
              },
              onCompleted: () => {
                dispatch(lockAndReloadEggDetail({ tokenId }));
                dispatch(hammerBurnActions.setBurnStatusLoading(false));
              },
            }),
          );
        }, 5000);

        return result;
      }
    } catch (err: any) {
      if (isLedgerConnected) {
        toastError(err);
      }
      console.log(`🚀 ~ onBurnHammer ~ err:`, err.message);
      toast.warn(messagesDetail.buildDeployFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
      dispatch(hammerBurnActions.setBurnStatusLoading(false));
    } finally {
      // onHideConfirmModalHandler();
    }
  }, [
    HAMMER_BURN_PAYMENT_AMOUNT,
    burnHammer,
    checkBalanceAgainstAmount,
    dispatch,
    image,
    isLedgerConnected,
    selectedNFT,
    toastError,
    tokenId,
  ]);

  const onClickHammerBurnInstructionModal = useCallback(() => {
    dispatch(hammerBurnActions.showBurnInstructionModal());
  }, [dispatch]);

  const onSelectHammerHandler = useCallback(
    (id: string) => {
      dispatch(hammerBurnActions.selectNFT(id));
    },
    [dispatch],
  );

  const renderBoxes = useCallback(() => {
    if (isLoading && pagination.page === null) {
      return (
        <div className="icon--loading">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    if (!isLoading && !viewData?.length) {
      // If deployHash appears as a list, this means a minting process in in progress
      return (
        <EmptyHammersToBurn
          isValidating={isValidating}
          isLoading={isLoading}
          reload={refetch}
        />
      );
    }

    return (
      <div style={{ height: 120 }}>
        <CardCarousel>
          {viewData?.map((item: any) => (
            <div
              key={`hammer-slot--${item.tokenId}`}
              className="flex-none embla__slide"
            >
              <Box
                selected={item.tokenId === selectedNFT}
                key={`egg-box--${item.tokenId}`}
                item={item}
                isDeploying={isDeploying}
                onClick={onSelectHammerHandler}
              />
            </div>
          ))}
        </CardCarousel>
        <InfiniteLoader
          showText={false}
          hasMore={hasMore}
          fetchMore={fetchMore}
          loading={isLoading}
        />
        {/* <CustomScrollbar isAlwaysVisible>
          <div className="nft-grid-compact--container hammer-burn-grid">
            {viewData?.map((item: any) => (
              <Box
                selected={item.tokenId === selectedNFT}
                key={`egg-box--${item.tokenId}`}
                item={item}
                isDeploying={isDeploying}
                onClick={onSelectHammerHandler}
              />
            ))}
          </div>
          <InfiniteLoader
            hasMore={hasMore}
            fetchMore={fetchMore}
            loading={isLoading}
          />
        </CustomScrollbar> */}
      </div>
    );
  }, [
    isLoading,
    pagination.page,
    viewData,
    hasMore,
    fetchMore,
    isValidating,
    refetch,
    selectedNFT,
    isDeploying,
    onSelectHammerHandler,
  ]);

  if (!open || !dataNFTDetail) {
    return null;
  }

  return (
    <GModal
      blurOverlay
      show={open}
      className={'hammer-burn--modal'}
      onHide={onCloseHandler}
      disabledClose={isDeploying}
    >
      <div className="body-wrapper">
        <div className="column--top">
          <div className="column--current-egg">
            <img
              src={image.value}
              alt="Egg"
              className="hammer-burn--element egg"
            />
          </div>
          <div className="column--middle">X</div>
          <div className="column--hammer">
            <FireCircle />
            {selectedHammer && (
              <img
                src={
                  selectedHammer.imageUrl ? selectedHammer.imageUrl : Sample2
                }
                alt={selectedHammer.contractName}
                className="hammer-burn--element hammer"
              />
            )}
          </div>
        </div>
        <div className="column--hammer-list">
          <div
            className={cn('egg-merge--right-column', {
              'is-loading': isLoading,
            })}
          >
            {renderBoxes()}
          </div>
        </div>
      </div>

      <NetworkFeePanel fee={HAMMER_BURN_PAYMENT_AMOUNT} isMote={false} />
      <div className="actions">
        <Button
          disabled={shouldDisableHammerBurnButton}
          className="inside-nft-detail btn--nft-action btn--evolve-dragon"
          onClick={onBurnHammer}
          btnStyle="5"
          size="xl"
        >
          {statusLoading ? (
            <>
              <LoadingBox
                isHorizontal
                label="HAMMER BURN"
                className="loading--making-dragon"
                minHeight={32}
              />
            </>
          ) : (
            <>
              <img
                className="icon icon--claim-snc"
                src={iconHatch}
                alt={commonMessages.labelHammerBurn.defaultMessage}
              />
              {commonMessages.labelHammerBurn.defaultMessage}
            </>
          )}
        </Button>
        <button
          onClick={onClickHammerBurnInstructionModal}
          className={'icon--how-to-merge'}
        >
          <QuestionCircleFill />
        </button>
      </div>
      <HammerBurnInstructrionModal />
      <HammerBurnStatusModal onClose={onCloseHandler} />
    </GModal>
  );
};

export default HammerBurnModal;
